<template>
  <div id="sendCoupon">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addCoupon"
              type="primary"
              @click="addCoupon"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-merchantTab-detail-sendCoupon_Tab-add'
                )
              "
              ><i class="iconfont icon-jiahao"></i> 新增账号</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="deviceName" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="deviceType" label="联系方式" align="center">
        </el-table-column>
        <el-table-column prop="deviceCode" label="发放数量" align="center">
        </el-table-column>
        <el-table-column prop="deviceCode" label="使用数量" align="center">
        </el-table-column>
        <el-table-column prop="deviceCode" label="使用金额" align="center">
        </el-table-column>
        <el-table-column prop="deviceCode" label="登录名" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-merchantTab-detail-sendCoupon_Tab-edit'
                )
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="del(scope.row.deviceChannelId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-merchantTab-detail-sendCoupon_Tab-delete'
                )
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <edit-dialog ref="editDialog" :getList="getList" />
  </div>
</template>
<script>
export default {
  components: {
    editDialog: () => import("./editDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },

      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.$message.error("发券账号没接口");
    // this.getList();
  },
  methods: {
    addCoupon() {
      this.$refs.editDialog.show = true;
      this.$refs.editDialog.title = "添加设备";
    },
    del(deviceChannelId) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(
            `/channel/device/${deviceChannelId}/delete`
          );
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    edit(row) {
      this.$refs.editDialog.addForm = {
        deviceName: row.deviceName,
        deviceType: row.deviceType,
        deviceCode: row.deviceCode,
        serverId: row.serverId,
        deviceId: row.deviceId,
      };
      this.$refs.editDialog.title = "编辑设备";
      this.$refs.editDialog.show = true;
    },
    reset() {
      this.filtrate = {
        keyword: "",
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/channel/device/list", {
          channelId: this.$route.params.id,
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.faultTime = this.$moment(item.faultTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.repairTime = this.$moment(item.repairTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#sendCoupon {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addCoupon {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
